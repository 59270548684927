import request from '@/http'
var env = require("../../env")
// VUE_APP_APIUSER
const authUrlType = env[process.env.environment].VUE_APP_AUTHURLTYPE
const usercenter_url = env[process.env.environment].VUE_APP_APIUSER; // memberCenter
const base_url = env[process.env.environment].VUE_BASE_URL;
const apiAuth = {
  // getAuthTree: rid => request.get(url + authUrlType + `/gv1/sysMicro/getMenu/${rid}/member`),
  // getAuthList: () => request.get(url + authUrlType + `/sv1/user`),
  // // getMenus: () => request.get(url+ auth + `/v1/sys/menu/getMenu/member`),
  // getMenus: (type,orgNo) => request.get(url + `/auth/v1/permit/jybMenuId/${type}/${orgNo}/member`),
  sendLogout: () => request.get(base_url + authUrlType + '/logout'),
  // getSMSLoginCode: (data) => request.post(url + authUrlType + '/ov1/sms', data),
  // companyCut: (no) => request.get(`${url}${authUrlType}/sv1/cut/${no}`),
  // userJoinCompany: (userNo) => request.get(`${process.env.VUE_APP_API}${process.env.VUE_APP_ENTERPRISE}/v1/organization/isUserInCompany/${userNo}`),
  // getComList: (params) => request.get(`${process.env.VUE_APP_APIUSER}/userJoinCompanyAudit/searchComList`, params),
  // postApplyCompany: (params) => request.post(`${process.env.VUE_APP_APIUSER}/userJoinCompanyAudit/apply`, params),
  // // 切换主企业
  // updateDefaultCompany: (data) => request.post(url + process.env.VUE_APP_ENTERPRISE + '/v1/organization/updateDefaultCompany', data),
  //切换公司
  // changeCompany: (no) => request.get(url + process.env.VUE_APP_AUTHURLTYPE + `/sv1/cut/${no}`,),
  NewChangeCompany: (type,orgNo,) => request.put(`${base_url}/authentication/v1/user/${type}/${orgNo}/cut`,),
  getUserCompany: () => request.get(`${usercenter_url}/api/user/getUserCompany`,),
  // getUserTeamEnable: () => request.get(process.env.VUE_APP_APIUSER + `/teamService/getUserTeamEnable`),//查询用户认证的团队是否被禁用
  // 获取企业/个人缴费状态
  getUserCurrentServiceStatus: (params) => request.post(`${usercenter_url}/ServiceFeeOrder/queryServiceFeeInfoByNoAndType`, params)
}
export default apiAuth
