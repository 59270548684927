import request from '@/http'
var env = require("../../env")
const base_url = env[process.env.environment].VUE_SERVERNAME_JYBUSERPAGE
// const base_url = 'http://charlie.ngrok.qiguanbang.com:7081'
const apiRecommendCompany = {
  // sendLogout: () => request.get(base_url + authUrlType + '/logout'),
  getAllIndexPage: (params) => request.post(`${base_url}/v1/index/getAllIndexPage`, params),
  getCompanyTitle: () => request.get(`${base_url}/v1/index/getIndexCompanyTitle`,),
}
export default apiRecommendCompany
