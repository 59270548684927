let env = require("../../env");
let url = env[process.env.environment].VUE_APP_APIKNOWLEDGE_SHARING;
import http from "@/http";

export const apiKnowledge = {
  //知识分享视频列表
  getZsfxVideoList: params =>
    http.get(url + "/index/shareKnowledgeList", params),
  getvodList: params => http.get(url + "/index/vodList", params),
  // 根据VideoId获取视频播放地址
  getPlayUrl: id => http.get(`${url}/video/getPalyUrl?videoId=` + id),
  //点赞
  like: id => http.get(url + "/index/like/" + id),
  follow: (userNo, comNo) => http.post(url + "/vodFens/fens" , {userNo,comNo}),
  //获取分类视频
  getCategoryVideoList: params =>
    http.post(url + "/index/searchIndexList", params),
  // 获取所有的分类
  getKnowledgeList: () => http.get(url + "/v1/admin/category/list")
};

