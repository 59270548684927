import http from "@/http";
var env = require("../../env");
const api = env[process.env.environment].VUE_APP_APIMACHINERY;
const api1 = env[process.env.environment].VUE_APP_LOGISTICS;
let url = api;
const apiSearch = {
  keywords: params => http.get("/api/search/suggest", params),
  searchRentInfo: (params, data) =>
    http.postData("/api/search/findItem", params, data), // ?page=1&limit=12&words=吐鲁番 出租信息关键词搜索 get请求
  searchRentInfoNew: (params, data) =>
    http.postData(api1 + "/index/goodsSource/searchByKeyword", params, data), // ?page=1&limit=12&words=吐鲁番 出租信息关键词搜索 get请求
  searchRentInfoM: (params, data) =>
    http.postData(api1 + "/index/driver/searchByKeyword", params, data), // ?page=1&limit=12&words=吐鲁番 出租信息关键词搜索 get请求
  // searchWantedInfo: (params, data) => http.postData('/search/searchWantedInfo', params, data), // ?page=1&limit=12&words=吐鲁番 求租信息关键词搜索 get请求
  searchKeywordsCount: params =>
    http.get("/api/search/searchKeywordsCount", params) // ?words=乌鲁木齐 关键词分类数量统计 get请求
};
// /search/searchRentInfo
// /search/searchWantedInfo?page=1&limit=12&words=吐鲁番 求租信息关键词搜索 get请求
// /search/searchKeywordsCount?words=乌鲁木齐 关键词分类数量统计 get请求
export default apiSearch;

