// import axios from 'axios';
import http from "@/http"
import cookie from '@/storage/cookies.js'

var env = require("../../env");

const api8 = env[process.env.environment].VUE_APP_APIRECRUITMENT; // 招采
const api1 = env[process.env.environment].VUE_APP_FOCUSPURCHASES; // 集采
const api2 = env[process.env.environment].VUE_APP_APIMACHINERY; // 机械
const api3 = env[process.env.environment].VUE_APP_APIEQUIPMENT; // 设备
const api4 = env[process.env.environment].VUE_APP_TEAM // 工队
const base_url = env[process.env.environment].VUE_BASE_URL
// let http = axios.create();

// http.defaults.timeout = 50000;
// http.defaults.baseURL = process.env.VUE_APP_API;

// http.interceptors.request.use(
//   config => {
//     let token = cookie.cookieRead('token');
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`
//     }

//     let arr = ['/api/search/searchKeywordsCount', '/api/search/findItem'];
//     let indexOf = config.url.indexOf('/api/search/searchKeywordsCount');
//     let indexOf1 = config.url.indexOf('/api/search/findItem');
//     let indexOf2 = config.url.indexOf('/v1/commodity/summary');
//     let indexOf3 = config.url.indexOf('/v1/comService/searchComList');
//     // let indexOf4 = config.url.indexOf('/ov1/mechanicalLabor/tenderBidderList');
//     if (indexOf !== -1 || indexOf1 !== -1 || indexOf2 !== -1 || indexOf3 !== -1) {
//       if (config.method == 'post') {
//         config.data = {
//           ...config.data,
//         }
//       } else if (config.method == 'get') {
//         config.params = {
//           ...config.params
//         }
//       }
//       return config
//     }


//     let cookieArea = cookie.cookieRead('area');
//     let area = cookieArea ? JSON.parse(cookieArea) : null;
//     if (area) {
//       if (config.method == 'post') {
//         config.data = {
//           ...config.data,
//         }
//       } else if (config.method == 'get') {
//         config.params = {
//           ...config.params
//         }
//       }
//     }
//     return config
//   },
//   error => {
//     return Promise.reject(error)
//   }
// );
const apiScrollList = {
  // 服务大厅
  getServiceHall: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(base_url + "/sgb-enterpriseservices/serviceHall/list", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getNoticeList: () => http.post(api8 + `/ov1/mechanicalLabor/tenderApplyingList`, {
    "limit": 20,
    "page": 1,
    "total": 0,
    "type": "",
    "city": "",
    "name": "",
    "state": "",
    "areacode": "",
    "verticalType": "",
    "goodsType": "",
    "time": "",
    "price": ""
  }),
    getbidList: () => http.post(api8 + `/ov1/tender/es/queryHomeEsPage`, {
    "limit": 10,
    "page": 1,
    "total": 0,
    "type": "",
    "city": "",
    "name": "",
    "state": 2,
    "areacode": "",
    "verticalType": "",
    "goodsType": "",
    "time": "",
    "price": "",
    "queryPosition": 9
  }),
  getJicaiList: () => http.post(api1 + `/index/search`, {
    allSearchKey: "",
    attrs: [],
    categoryfirsType: "",
    categorysecondType: "",
    cityNo: "",
    current: 1,
    endDayType: "",
    firstType: "",
    goodsName: null,
    groupBuyClass: null,
    groupBuyType: null,
    groupbuyClass: null,
    groupbuyMode: 1,
    isNeed: true,
    joinType: "",
    limit: 10,
    machinefirstType: "",
    machinesecondType: "",
    offerEndDayType: "",
    offerType: "",
    order: "",
    provinceNo: "",
    searchKey: "",
    secondType: "",
    sortType: 1,
    status: 1,
    thirdType: "",
    timeIntervals: ""
  }),
  // getRentJixieList: () => http.post(api2 + `/search/getMachineryByCondition?page=1&limit=20&timeSort=0`, {
  getRentJixieList: (data) => http.post(api3 + `/lease/getAllLeaseHomeList`, data),
  getListRentSeekXQ: (data) => http.post(base_url + `/mg-es-service/v1/api/index/askLease/getAllRentLeaseInfo`, data),
  getServiceNeed: (data) => http.post(base_url + `/sgb-enterpriseservices/demand/list`, data),
  getNeweSourseList: () => http.post(api4 + `/teamInfo/new/search`, {
    page: 1,
    limit: 10,
    teamSize: null,
    // isCompany: 1,
    teamStatus: null,
    provinceNo: '',
    areacode: ''
  }),
  getfindJob: (data) => http.post(api4 + `/employment/front`, data),
}
export default apiScrollList;
