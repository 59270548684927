//
// 认证管理
//
import request from '@/http'
var env = require("../../env");
let api = env[process.env.environment].VUE_APP_API_APISTORE
let apiMaterials = env[process.env.environment].VUE_APP_APIMATERIALS
let apiUser = env[process.env.environment].VUE_APP_APIUSER

const apiAuthentication = {

  business: () => request.get('/api/auth/business'), // 采购商主营业务一级分类
  businessByCompany: () => request.get('/api/auth/businessByCompany'), // 根据企业注册地 获取主营业务一级分类
  // *********
  getCompanyAuthInfo: () => request.get('/api/auth/getCompanyAuthInfo'), // 获取企业信息
  companyAuth: (data) => request.post('/api/auth/companyAuth', data), // 企业认证

  storeInformation: (form) => request.post(apiUser + '/v2/api/companyInfo/save', form), // 保存店铺信息 old
  // storeInformation: (form) => request.post(api + '/api/companyInfo', form), // 保存店铺信息 old

  getStoreInformation: () => request.post(apiUser + '/v2/api/companyInfo/details'), // 获取店铺信息
  closeShopGuidance: () => request.post('/api/user/closeGuidanceOpenCompanyInfo'), // 关闭店铺引导
  getStoreInformationOld: () => request.get(api + '/api/companyInfo'), // 获取店铺信息 old

  getServiceTypeList: () => request.get('/v2/api/companyInfo/getServiceTypeList'), // 服务列表
  dict: () => request.get(apiMaterials + '/dict/12'),
  logoAddImg: (params) => request.post(apiUser + '/file/add', params), // logo上传
  // 实名认证
  getRealName: (params) => request.post(apiUser + '/api/auth/userAuth', params),
  getBusinessLicenseInfoByBase64: (data) => request.post(apiUser + '/file/getBusinessLicenseInfoByBase64', data)

}
export default apiAuthentication
