import axios from '@/http'
import store from '@/store/index'
import {
  Message,
  MessageBox
} from 'element-ui'

export const apiCart = {
  // 添加加购物车
  addCart: async function (itemId, itemNum = 1000) {
    //未认证
    if (store().state.user.type === '2') {
      Message.error('请先到电脑端进行供应商或采购商认证')
      // '请先到电脑端进行供应商或采购商认证'
      return
    }
    return new Promise((resolve, reject) => {
      axios({
          method: 'post',
          url: `api/cart/addCart`,
          data: {
            itemId: itemId,
            itemNum: itemNum
          }
        })
        .then(res => {
          if( res.data.msg == 'success'){
            Message.success({
              message: '成功加入购物车',
              center: true
            })
          }else{
            Message.error(res.data.msg)
            // Message.success({
            //   message: res.data.msg,
            //   center: true
            // })
          }
 
          resolve(res)
        })
        .catch(err => {
          Message.error('您没有操作权限，请重新登录后尝试')
          reject(err)
        })
    })
  },
  // 更新购物车数量
  updateCart: async function (id, itemNum = 1) {
    return new Promise((resolve, reject) => {
      axios({
          method: 'put',
          url: `/api/cart/updateCart`,
          data: {
            id: id,
            itemNum: itemNum
          }
        })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  // 校验购物车商品是否被删除
  checkCartItem: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/cart/checkCartItem',data)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // 获取购物车列表
  getCartList: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/cart/getCartList')
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  //删除指定购物车商品
  deleteByidst: async function (ids) {
    return new Promise((resolve, reject) => {
      // 我的收货地址
      axios({
          method: 'post',
          url: `api/cart/deleteByids`,
          data: {
            ids: ids
          }
        })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  //删除所有失效商品
  deleteInvalidItem: async function () {
    return new Promise((resolve, reject) => {
      // 我的收货地址
      axios({
          method: 'post',
          url: `api/cart/deleteInvalidItem`
        })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // 清空购物车
  deleteAll: async function () {
    return new Promise((resolve, reject) => {
      // 我的收货地址
      axios({
          method: 'post',
          url: `/api/cart/deleteAll`
        })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // 获取用户地址
  getUserAddress: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/cart/getUserAddress')
        .then(res => {

          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // 获取用户公司信息
  getUserCompany: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/cart/getUserCompany')
        .then(res => {

          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // 获取用户关联的开票公司
  getCompanyByUser:async function() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/cart/getCompanyByUser`)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  // 商品列表  新
  newGetCartList:async function() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/v2/api/cart/getCartList`)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

     // 
}
