import axios from "@/http";
const env = require("../../env");
// import http1 from 'axios'
// const req = http1.create({})
// VUE_APP_APIUSER
const url = env[process.env.environment].VUE_APP_APIUSER;
const bserUrl = env[process.env.environment].VUE_BASE_URL;
// 企业大全服务地址
const url_com = env[process.env.environment].VUE_APP_REVIEW;
const url_jybAppPage = env[process.env.environment].VUE_SERVERNAME_JYBUSERPAGE;
const server_VUE_APP_ENTERPRISE =
  env[process.env.environment].VUE_APP_ENTERPRISE;
export const enterpriseHttp = {
  // 企业大全
  recommendList: params =>
    axios.post(`${url}/api/company/BusinessHallComList`, params),
  // 专项资质列表
  // specialSessionList: params => axios.post(`${url}/companyInfoQualification/findMyListByCompanyInfoNo`,params),
  specialSessionList: params =>
    axios.post(
      `${url_jybAppPage}/api/allSiteInfoQualification/findMyListByCompanyInfoNo`,
      params
    ),
  // 知识产权
  // knowledgeList: params => axios.post(`${url}/intellectualPropertyRight/findPropertyRight`,params),
  knowledgeList: params =>
    axios.post(
      `${url_jybAppPage}/api/allSiteIntellectualPropertyRight/findPropertyRight`,
      params
    ),
  // 专项资产
  // propertyList: params => axios.get(`${url}/api/asset/findManagementList`,params),
  propertyList: params =>
    axios.get(
      `${bserUrl}${server_VUE_APP_ENTERPRISE}/v1/api/asset/getAssetListByType`,
      params
    ),
  // 荣誉荣耀
  // honorList: params => axios.post(`${url}/receptionHonor/findIndustryHonorList`,params),
  honorList: params =>
    axios.post(
      `${url_jybAppPage}/api/allSiteReceptionHonor/findIndustryHonorList`,
      params
    ),
  // 经营动态
  // dynamicList: params => axios.post(`${url}/companyNews/findNewsByCompanyNo`,params),
  dynamicList: params =>
    axios.post(`${url_jybAppPage}/api/allSiteNews/findNewsByCompanyNo`, params),

  // company service request http
  /**
   * 服务大厅-服务分类
   * @param {*} data
   * @returns
   */
  getServiceType: async function() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${bserUrl}/sgb-enterpriseservices/serviceClass/getAllServiceClass`,
          {}
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取服务大厅企业no列表
  getServiceComNoList: async function(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${bserUrl}/sgb-enterpriseservices/serviceHall/getCompanyNoOrTeamNo`,
          data
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  /**
   * 服务大厅-企业列表
   * @param {*} data
   * @returns
   */
  getServiceHallList: params =>
    axios.post(`${bserUrl}/memberCenter/serviceHall/company`, params),

  /**
   * 服务大厅分类查询
   * @param {*} data
   * @returns
   */
  getServiceList: async function(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${bserUrl}/sgb-enterpriseservices/serviceHall/list`, data)
        // .post(`${bserUrl}/dataMerge/list`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 服务大厅查询全部
  getServiceAllList: async function(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${bserUrl}/sgb-financeService/dataMerge/list`, data)
        // .post(`${bserUrl}/dataMerge/list`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  //  getServiceList:params => axios.post(`${bserUrl}/serviceHall/list`,params),
  //  async function (data) {
  //      // getCompanyNoOrTeamNo
  //     return new Promise((resolve, reject) => {
  //         req.post(`${bserUrl}/memberCenter/serviceHall/company`, data)
  //         .then(res => {
  //             resolve(res);
  //         })
  //         .catch(err => {
  //             reject(err);
  //         });
  //     });
  // },
  /**
   * 需求大厅-列表
   */
  getNeedList: async function(data) {
    // https://gateway.shigongbang.com/
    return new Promise((resolve, reject) => {
      axios
        // .post(`${bserUrl}/sgb-enterpriseservices/demand/list`, data)
        .post(`${bserUrl}/sgb-enterpriseservices/demand/list`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  /**
   * 需求大厅-列表
   */
  getTransferHallList: async function(data, urlFiled) {
    // https://gateway.shigongbang.com/
    return new Promise((resolve, reject) => {
      axios
        .post(`${bserUrl}/sgb-enterpriseservices/${urlFiled}`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取服务团队列表
  getServiceTeamList: data => axios.post('sgb-enterpriseservices/serviceHall/serviceTeamList', data)
  // serviceTeamList
  // sgb-enterpriseservices/serviceHall/serviceTeamList
};

