import http from "@/http";
let serverName = "invite-investments";
// 招商引资获取 引资项目 查询类型
export const getIndustryCategoryTrees = data =>
  http.get(serverName + "/imItems/industryCategoryTrees", data);
// 招商引资获取 投资需求 查询类型
export const getInvestmentDemandTypes = data =>
  http.patch(serverName + "/imInvestmentDemandTypes/findList", data);
// 招商引资获取 产业园区 查询类型
export const getInvestmentAdelevelTypes = data =>
  http.get(serverName + "/imAssistantSectionLevels/web/findList", data);
// 招商引资获取 会议会展 查询类型
export const getImPreferentialPolicyCategories = data =>
  http.post(serverName + "/imConferenceExhibitionCategories/findPage", {
    limit: 1000,
    page: 1,
    status: 1
  });
// 招商引资获取 引资项目列表查询
export const getIndustryCategoryList = data =>
  http.post(serverName + "/imItems/findPage", data);
// 招商引资获取 投资需求列表查询
export const getInvestmentDemandsList = data =>
  http.post(serverName + "/imInvestmentDemands/findCPage", data);
// 招商引资获取 产业园区列表查询
export const getImIndustrialParksList = data =>
  http.post(serverName + "/imIndustrialParks/pc/findPage", data);
// 招商引资获取 会议会展列表查询
export const getImPreferentialPolicyList = data =>
  http.post(serverName + "/ov1/imConferenceExhibition/findPage", data);
