import axios from "@/http";
import request from "@/http";
import cookie from "@/storage/cookies";
var env = require("../../env");
import baseAxios from "axios";
import { reject } from "lodash-es";
let baseAxios_ = baseAxios.create();
const api = env[process.env.environment].VUE_APP_LOGISTICS;
const api1 = env[process.env.environment].VUE_APP_APIEQUIPMENT;
const api2 = env[process.env.environment].VUE_APP_CONSTRUCTIONPOWER;
const api3 = env[process.env.environment].VUE_APP_APIKNOWLEDGE_SHARING;
const api4 = env[process.env.environment].VUE_APP_FOCUSPURCHASES;
const api5 = env[process.env.environment].VUE_APP_TEAM;
const api6 = env[process.env.environment].VUE_APP_APIMATERIALS;
const api7 = env[process.env.environment].VUE_APP_APIUSER;
const api8 = env[process.env.environment].VUE_APP_APIRECRUITMENT;
const apiReview = env[process.env.environment].VUE_APP_REVIEW;
const materials_new = env[process.env.environment].VUE_APP_APIMATERIALS_NEW;
const jixie = env[process.env.environment].VUE_APP_MECHANICAL;
const groupbuy = env[process.env.environment].VUE_APP_GROUPBUY;
const url_adver = env[process.env.environment].VUE_APP_ADVER; // 广告url
const url_service = env[process.env.environment].VUE_APP_LOGINAPI; // 企业服务不带微服务url
const url_appserve = env[process.env.environment].VUE_APP_SYNC_TOKEN; // 同步token
const url_baseUrl = env[process.env.environment].VUE_BASE_URL;
const url_enterUrl = env[process.env.environment].VUE_APP_ENTERPRISE;
const authurltype = env[process.env.environment].VUE_APP_AUTHURLTYPE;
const url_jyb_user_page =
  env[process.env.environment].VUE_SERVERNAME_JYBUSERPAGE;
const url_perchase = env[process.env.environment].VUE_GOODSPERCHASE;
let url = api;
let url1 = api1;
let url2 = api2;
export const apiHome = {
  companyRecommend: function (data) {
    //token 同步
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${url_baseUrl}/jyb-user-page/admin/allSiteInfo/companyRecommend`,
          data
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getAllIndexList: params =>
    request.get(`${url_jyb_user_page}/v1/index/getAllIndexList`, params),
  getIdentifiers: () => request.get(`${url_service}/authentication/sv1/user`), //用户权限
  // getMachineInfoList: () => request.get(url + '/api/index/rentList'),	// 首冶机械推荐
  // 地区
  getArea: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/area/list`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  //获取当前用户的供应商类型的会员
  getSupplierMember: () => axios.post(api6 + "/enquiry/getSupplierMember"),
  getAreas: () => request.get(`${api7}/common/getAllAreaList`), // 获取地区
  getAllAreaListAdmin: params => axios.get(`${api}/area`, params),
  getNewCityList: params => axios.get(`${api}/area/pinyinSearch`, params),
  getHotKey: () => axios.get(api6 + "/api/search/getHotKey"),
  getReviewHotKey: () => axios.get(apiReview + "/hotWordsConfig/getHotWord"),
  // getHotKey: async function () {
  //   return new Promise((resolve, reject) => {
  //     axios({
  //       method: 'get',
  //       url: `/api/search/getHotKey`
  //     })
  //       .then(res => {
  //         resolve(res)
  //       })
  //       .catch(err => {
  //         reject(err)
  //       })
  //   })
  // },
  // 搜索接口
  getPricMenuList: async () => {
    return new Promise(async (resolve, reject) => {
      axios
        .post(api6 + `/tbIndustry/queryAllBefore16`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 首页banner图
  getBannerList: async function () {
    return new Promise((resolve, reject) => {
      // axios.get(url1 + `/api/equipindex/getBanner`).then(res => {
      axios
        .post(url + `/sysBanner/list`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // searchGoods: function (key, cid, page = 1, size = 10) {
  //   let id = JSON.parse(cookie.cookieRead("area")).id;
  //   return new Promise((resolve, reject) => {
  //     axios.get(`${api6}/api/search/searchItem?item_provinceId=${id}&&key=${key}&&cid=${cid}&&page=${page}&&size=${size}`)
  //   })
  // },

  // 首页菜单
  newsearchGoods: (key, cid, page = 1, size = 10) => {
    let id;
    if (process.client) {
      id = JSON.parse(cookie.cookieRead("area")).id;
    }
    return request.get(api6 + "/api/search/searchItem", {
      item_provinceId: "",
      key: key,
      cid: cid,
      page: page,
      size: size
    });
  },
  getCategoryList: () =>
    request.get(url1 + "/api/equipindex/getAllParentCategory"),
  getJsllCategoryList: () => request.get(url2 + "/api/font/category"),
  getZsfxCategoryList: () => request.get(api3 + "/v1/api/category/list"),
  getJicaiCategoryList: () => request.get(api4 + "/index/typeList"),
  getGongduiCategoryList: () =>
    request.get(api5 + "/api/index/getTeamCategory"),

  getCompanyInfo: () => request.get(api7 + "/api/auth/getCompanyInfo"), // 获取企业信息
  getQueryParentId: async id => {
    return new Promise(async (resolve, reject) => {
      axios
        .get(`${api6}/tbCategory/queryParentId/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 机械菜单
  getJixieMenu: async () => {
    return new Promise(async (resolve, reject) => {
      axios
        .get(url + `/api/index/categoryList`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 求租信息列表
  fetchDemandRentInfoList: async () => {
    return new Promise(async (resolve, reject) => {
      axios({
        method: "get",
        url: `/api/index/wantList`
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 出租信息列表
  fetchRentInfoList: async () => {
    return new Promise(async (resolve, reject) => {
      axios
        .post(url + "/index/recommendCarList")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 首页机械推荐
  getMachineInfoList: async () => {
    return new Promise(async (resolve, reject) => {
      axios
        .get(url + `/api/index/rentList`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 推荐租赁企业列表
  fetchCompanyList: async () => {
    return new Promise(async (resolve, reject) => {
      axios
        .get("/api/equipindex/getRecommendCompany")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // getMenuList: async () => {
  //   return new Promise(async (resolve, reject) => {
  //     axios
  //       .get(env[process.env.environment].VUE_APP_MENUURL)
  //       .then(res => {
  //         resolve(res);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // },
  getMenuList: async () => {
    return new Promise(async (resolve, reject) => {
      axios
        .get(
          env[process.env.environment].VUE_SERVERNAME_JYBUSERPAGE +
          "/v1/index/getMenuList"
        )
        // .get('http://charlie.ngrok.qiguanbang.com:7081'+'/v1/index/getMenuList')
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  setMenuByUser(params) {
    return new Promise(async (resolve, reject) => {
      axios
        .post(
          env[process.env.environment].VUE_SERVERNAME_JYBUSERPAGE +
          "/v1/index/addMenuList",
          params
        )
        // axios.post('http://charlie.ngrok.qiguanbang.com:7081'+'/v1/index/addMenuList', params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 推荐找货列表
  recommendGoodsSourceList: async () => {
    return new Promise(async (resolve, reject) => {
      axios
        .post(url + "/index/recommendGoodsSourceList")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 判断司机
  driverAuthDetail: async data => {
    return new Promise(async (resolve, reject) => {
      axios
        .get(url + "/driverAuth/detail", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 承运详情
  carrierDetail: async data => {
    return new Promise(async (resolve, reject) => {
      axios
        .get(url + "/carrier/detail?no=" + data.no)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 司机详情
  driverAuth: async data => {
    return new Promise(async (resolve, reject) => {
      axios
        .get(url + "/driverAuth/driverDetail/" + data.no)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 个人中心跳转到司机详情
  driverDetail: async data => {
    return new Promise(async (resolve, reject) => {
      axios
        .get(url + "/goodsSource/driverDetail", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 货源详情
  goodsSourceDetail: async data => {
    return new Promise(async (resolve, reject) => {
      axios
        .get(url + "/goodsSource/detail", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 找货信息搜索
  searchByKeyword: async data => {
    return new Promise(async (resolve, reject) => {
      axios
        .get(url1 + "/index/goodsSource/searchByKeyword", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  //获取意见反馈所属板块
  getPlate: async data => {
    return new Promise(async (resolve, reject) => {
      axios
        .get(api6 + "/siteFeedback/plateSelect", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  //提交反馈意见
  siteFeedback: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api6 + `/siteFeedback`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  //获取类目
  getNewCatList: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get(api6 + "/homeCategory/indexCategoryNew?areaCode=" + 650000)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getLevelOneCatList: () =>
    request.get(api6 + "/api/itemCat/getListByAreaCode?areacode=" + 650000), //获取全部分类

  getCatListByAreaCode: () =>
    request.get(api6 + "/api/itemCat/getCatListByAreaCode?areacode=" + 650000), //获取首页一级分类

  //根据一级分类获取商品
  getGoodsByCatId: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(materials_new + "/v1/commodity/saleSkuList", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取询价列表
  getProcurementList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(materials_new + "/v1/enquiry/search", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 推荐供应商
  getSupplierList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(url_baseUrl + "/mg-es-service/base/company/distance", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getEngineeringTypeSelect: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get(api8 + "/ov1/demandNotice/engineeringTypeSelect")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 招标预告、竞价采购、中标公告菜单分类
  getAdvancMenu: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get(api8 + "/ov1/mechanicalLabor/tenderTypeSelectCustom?type=true")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 劳务用工-行业分类
  getlistChild: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get(api5 + "/occupationConfig/listChild")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 劳务用工-列表
  laborEmploymentList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api5 + "/laborEmployment/list", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 需求预告菜单分类
  getNoticeMenu: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get(api8 + "/ov1/mechanicalLabor/findTeamCategoryAllList")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 招标信息菜单分类
  getInfomationMenu: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api8 + "/gv1/information/tender/list", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 招标信息列表
  getInfomationList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api8 + "/ov1/tender/notice/list", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 需求预告
  getNeedList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api8 + "/old/v1/front/item", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 招标大厅 (原有的招标大厅)
  copyGetTenderList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api8 + "/ov1/tender/es/findTenderingHomeEsPage", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getTenderList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api8 + "/ov1/tender/es/queryHomeEsPage", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 竞价采购
  getbidList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api8 + "/ov1/tender/es/queryHomeEsPage", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 招标预告 需求预算
  getpreNoticeList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api8 + "/ov1/demandNotice/projectList", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 中标公告
  getbidNoticeList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api8 + "/ov1/base/biddingNotice/listNew", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  // 获取租赁服务分类
  getRentTypeList: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get(api1 + "/api/equipindex/getAllParentCategory")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取土地二级菜单列表
  getLandSecond: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get(`${api1}/land/landleaseCategory/getAll`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取土地列表
  getLandList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${api1}/lease/Landlease/getHomeList`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取工厂二级菜单列表
  getPlantSecond: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get(`${api1}/factory/category/getFactoryCategorySelect`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取工厂表
  getPlantList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${api1}/factory/release/homeList`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取农机二级菜单
  getFarmSecond: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get(`${api1}/farmMachinery/category/getFactoryCategorySelect`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取农机列表
  getFarmList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${api1}/farmMachinery/release/homeList`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 出租大厅-- 旧版已弃用
  async copygetOutLeaseList(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${url_baseUrl}/mg-es-service/v1/api/index/rentLease/getAllLeaseInfo`,
          data
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  async getOutLeaseList(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url_baseUrl}/sgb-shebei/lease/getHomeLeaseInfo`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取租赁工程机械商品列表
  getRentJixieList: async function (params, data) {
    return new Promise((resolve, reject) => {
      axios
        // .postParamsData(jixie + "/search/getMachineryByCondition", params, data)
        .postParamsData(jixie + "/rentInfo/getHomeList", params, data)

        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取租赁工程设备商品列表
  getRentEquipmentList: async function (params, data) {
    return new Promise((resolve, reject) => {
      axios
        .postParamsData(api1 + "/api/equipment/rentInfo/list", params, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取租赁工程设备商品列表
  getRentcarList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api1 + "/car/Release/homeList", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取租赁房产商品列表
  getRentHouseList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api1 + "/renl/estate/listRenlEstate", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  // 获取集采服务列表
  getJicaiList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api4 + "/index/search", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // url_adver  /app/top/getAdv
  // 获取顶部广告
  getTopAdverList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url_adver}/jyb-advertisement/app/top/getAdv`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取banner广告
  getBannerAdverList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url_adver}/jyb-advertisement/app/banner/getAdv`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取侧边广告
  getSliderAdverList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url_adver}/jyb-advertisement/app/homePageSide/getAdv`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取推荐工队列表
  getRecommendTeam: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .get(api5 + "/api/index/getRecommendTeam", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取推荐工程列表
  getRecommendEngineer: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .get(api5 + "/api/index/recommendEngineerList", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 工队-找工人列表
  getRecommendWorker: async function (params) {
    return new Promise((resolve, reject) => {
      axios
        .post(api5 + "/worker/list", params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  // 获取最新车主列表
  getCarOwner: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api + "/driverAuth/search", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  // 获取优质企业
  getNewestComList: async function (params) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiReview + "/v1/comService/newestComList", params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取施工工队
  getComList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api5 + "/teamInfo/listEngineeringCompany", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取施工工队
  getNeweSourseList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api5 + "/teamInfo/new/search", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 工队-招工用工列表
  getLaborList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api5 + "/employment/front", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 工队-招工用工分类
  // getTypeList: async function(data) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(api5 + "/employment/typeAndStatus", data)
  //       .then(res => {
  //         resolve(res);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // },
  GetClassification: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get(api5 + "/occupationConfig/listChild")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 工队-工种报价列表
  getTeamPriceList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api5 + "/teamPrice/findPriceList", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 工队-工种报价分类
  sysTeamCategory: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .get(api5 + "/api/sysTeamCategory/findCategoryListTree", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 找工人-一级分类列表
  sysTeamFindWorder: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .get(api5 + "/occupationConfig/listChild", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取最新货源
  getSupplyForGoods: async function (params) {
    return new Promise((resolve, reject) => {
      axios
        .post(api + "/index/recommendGoodsSourceList", params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 招标预告
  tenderNoticeList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api8 + "/ov1/mechanicalLabor/tenderNoticeList", data, {
          headers: {
            platform: "pc"
          }
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 招标公告
  getNoticeList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api8 + "/ov1/mechanicalLabor/tenderApplyingList", data, {
          headers: {
            platform: "pc"
          }
        })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 商砼列表
  getconcreteList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api6 + "/v1/search/concreteList", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取司机认证
  getPermissions: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get(api + "/user/getPermissions")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取求租公告
  getListRentSeekXQ: async function (params) {
    return new Promise((resolve, reject) => {
      axios
        .get(api1 + "/rent/seek/listRentSeekXQ", params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取找工程
  getfindJob: async function (params) {
    return new Promise((resolve, reject) => {
      axios
        .post(api5 + "/demandCenter/findJob", params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 获取首页交易滚动信息接口列表数据
  getRecommendList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${url_service}/sgb-enterpriseservices/demand/recommendList/popular`,
          data
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  //
  getAdvertisement: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiReview + "/homeAdvertisement/pc/list", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getcompanySpecific: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(groupbuy + "/index/search", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  getAllGoods: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .get(api6 + "/tbItemTask/page", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveUserTokenToRedis: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get(
          url_appserve + "/tokensync/saveUserTokenToRedis",
          {},
          {
            withCredentials: true
          }
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  delRedisUserToken: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          url_appserve + "/tokensync/delRedisUserToken",
          {},
          {
            withCredentials: true
          }
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  memberUser: function (data) {
    //token 同步
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${url_baseUrl}${authurltype}` + "/v1/user/memberUser/me",
          {},
          {
            withCredentials: true
          }
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 首页热词搜索
  getSearchGlobalList: params => {
    return axios.post(url_baseUrl + "/recommand/v1/global/search", params);
  },

  // 企业服务 代办服务列表
  getServiceRecommendList: params => {
    return axios.get(
      url_baseUrl +
      "/sgb-enterpriseservices/serviceHall/cooperate/recommendList",
      { pageSize: 1, limit: 10 }
    );
  },
  // 企业服务 专业团队
  getTeamList: params => {
    return axios.post(api7 + "/teamService/getTeamList", {
      cityCode: "",
      endScale: "",
      limit: 10,
      name: "",
      page: 1,
      provinceCode: "",
      sort: 1,
      startScale: ""
    });
  },
  // 拍卖大厅筛选
  getAuctionStatus: async function () {
    return new Promise((resolve, reject) => {
      axios
        .get(api8 + "/ov1/auction/auctionStateSelect")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 拍卖大厅
  getAuctionList: async function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api8 + "/ov1/auction/list", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  // 需求预告--商品采购
  getGoodsPurchase: function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(url_perchase + `/demandSchedule/frontList`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 需求预告-- 租赁
  getLeaseList: function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api8 + `/ov1/zcDemand/list`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 需求预告-- 其他
  getOtherDemand: function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api8 + `/ov1/otherDemand/list`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 需求预告-- 劳务工队
  getLabourDemand: function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api8 + `/ov1/labourDemand/list`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 需求预告-- 物流
  getLogisticsDemand: function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(api8 + `/ov1/logisticsDemand/list`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  //租赁经营 -- 需求大厅
  getAllRentLeaseInfo: function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url_baseUrl}/sgb-shebei/lease/getAllRentLeaseInfo`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // 租赁经营 -- 自营租赁 -- 公司
  getCompanyNos: () =>
    request.get(`${url_jyb_user_page}/api/allSiteInfo/getSelfSupportCompanyNo`),

  getAreaAll: () => request.get(api8 + `/ov1/api/area/getAllAreaList`),
  saveBussinessDetail: function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url_baseUrl}/jyb-user-page/businessAffair/save`, data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getComDynamicDate: function (data) {
    return new Promise((resolve, reject) => {
      axios
        .post(url_jyb_user_page + "/v1/index/getPage", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getDetailInfo: function (data) {
    return new Promise((resolve, reject) => {
      axios
        .get(url_jyb_user_page + "/v1/index/getDetail", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getRelationCompany: () =>
    request.get(
      `${url_enterUrl}/v1/organization/getCompany`
    ),
  getDepData: params =>
    request.get(`${url_enterUrl}/v1/organization/getDep/${params}`),
  getCategoryData: () => axios.get(`${url_jyb_user_page}/v1/homePageConfig/homePageTypeConfig`),
  getLeftNavList: () => axios.get(`${url_jyb_user_page}/v1/index/getLeftMenuList`),
  // pc页面黑白滤镜
  getPcHomePageBlack: (params) => axios.get(`/mg-dict-service/v1/api/dict/pcHomePageBlack`, params),
  // 需求预算下 物流货运
  getLogisticsDemandList: (params) => axios.post(api8 + '/ov1/logisticsDemand/list', params),
  // 需求预算下 劳务用工
  getLabourDemandList: (params) => axios.post(api8 + '/ov1/labourDemand/list', params),
  // 需求预算下 其他
  getOtherDemandList: (params) => axios.post(api8 + '/ov1/otherDemand/list', params),
  // 租赁 出租大厅 自定义一级类目
  getAllLeaseModel: (params) => axios.get(api1 + '/lease/getAllLeaseModel', params),
  // 工队下 工程公司 团队规模查询接口
  getTeamSize: (params) => axios.get(api5 + '/teamSize/findAll?type=0', params),
  // 获取是否有服务费
  checkWhetherOpen: (siteNo, siteType) => request.get(`${api7}/ServiceFeeOrder/checkWhetherOpen?companyNo=${siteNo}&siteType=${siteType}`),
  // 获取是否有服务费
  // homePageSecondTypeConfig: (params) => request.get(`${url_jyb_user_page}/v1/homePageConfig/homePageFirstTypeConfig`, params)
  homePageSecondTypeConfig: (params) => request.get(`${url_jyb_user_page}/v1/homePageConfig/homePageSecondTypeConfig`, params),
  getcommoditysHall: (params) => request.post(`${materials_new}/v1/commoditysHall/list`, params),
  getCompanyTypeIndex: () => request.get(`${url_jyb_user_page}/v1/companyIndex/getCompanyTypeIndex`, ),
  saveCompanyIndex: (params) => request.post(`${url_jyb_user_page}/v1/companyIndex/save`, params),
  // https://gateway.jingyingbang.com/shigongbang-es
}