import http from "@/http";
var env = require("../../env");
const url = env[process.env.environment].VUE_APP_LOGISTICS;
const url2 = env[process.env.environment].VUE_APP_APIUSER;
const url3 = env[process.env.environment].VUE_APP_MECHANICAL;
const url4 = env[process.env.environment].VUE_APP_APIMATERIALS;
const apiLogistics = {
  goodsType: params => http.get(url + "/goodsType/findCarLenList", params),
  carType: params => http.get(url + "/carType/findCarTypeList", params),
  carLen: params => http.get(url + "/carLen/findCarLenList", params),
  getAllAreaList: params => http.get(url2 + "/api/auth/getAllAreaList", params),
  getSimpleAreaList: params =>
    http.get(url3 + "/api/sysMachineryBasicArea/getSimpleAreaList", params),

  goodsSourceList: params => http.post(url + "/index/goodsSourceList", params),
  publish: params => http.post(url + "/goodsSource/publish", params),
  handleType: params => http.get(url + "/handleType/handleType", params),
  //推送承运信息
  pushCarrier: params => http.post(url + "/carrier/pushCarrier", params),
  //报价
  goodsOffer: params => http.post(url + "/goodsOffer/offer", params),
  //找车信息列表
  search: params => http.post(url + "/carrier/search", params),
  getBiddingCars: params => http.get(url + "/goodsSource/biddingCars", params), //竞价找车
  getQuotationOwner: params =>
    http.get(url + "/goodsSource/quotationOwner", params), //竞价找车
  getMyCarrierBusiness: params =>
    http.get(url + "/carrier/myCarrierBusiness", params), //承运商机
  getMyGoodsBusiness: params =>
    http.get(url + "/goodsSource/myGoodsBusiness", params), //货源商机
  postOfferQuote: params => http.post(url + "/goodsOffer/offer", params), //运费报价
  searchNew: params => http.post(url + "/driverAuth/search", params),
  homepageBanner: async function() {
    return new Promise((resolve, reject) => {
      http
        .get(`${url4}/homepageBanner/getBanners`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
export default apiLogistics;

