import axios from "@/http";
import axioss from "axios";
import request from "@/http";
import cookie from "@/storage/cookies";
import { reject } from "lodash";
var env = require("../../env");
const baseUrl = env[process.env.environment].VUE_BASE_URL; // 基础接口地址
const api = env[process.env.environment].VUE_APP_LOGISTICS; //logistics-test
const shebei_url = env[process.env.environment].VUE_APP_APIEQUIPMENT; //sgb-shebei
const api2 = env[process.env.environment].VUE_APP_CONSTRUCTIONPOWER;
const api3 = env[process.env.environment].VUE_APP_APIKNOWLEDGE_SHARING;
const api4 = env[process.env.environment].VUE_APP_FOCUSPURCHASES;
const api5 = env[process.env.environment].VUE_APP_TEAM;
const api6 = env[process.env.environment].VUE_APP_APIMATERIALS; //sgb-cailiao
const api7 = env[process.env.environment].VUE_APP_APIUSER; //memberCenter
const api8 = env[process.env.environment].VUE_APP_APIMATERIALS_NEW; //shigongbang-es
const api9 = env[process.env.environment].VUE_APP_REVIEW; //sgb-review
const jixie_url = env[process.env.environment].VUE_APP_MECHANICAL; //sgb-jixie
const jicai_url = env[process.env.environment].VUE_APP_GROUPBUY;
const zhaocai_url = env[process.env.environment].VUE_APP_APIRECRUITMENT;
// const api10 = "https://gateway.jingyingbang.com/sgb-jixie" //sgb-jixie
const api11 = "https://gateway.jingyingbang.com/sgb-shebei"; //sgb-shebei
// 租赁
const zulinUrl_es = `${baseUrl}/mg-es-service`;
// 物流
const wuliuUrl = env[process.env.environment].VUE_APP_LOGISTICS;
export const apiResSearch = {
  //企业网站相关
  //获取团队网站详情 ApiBaseUrl
  // getDetailByNo: (no) => request.get(`${baseUrl}/jyb-user-page/api/allSiteInfo/getDetailsByCompanyNo?companyNo=${no}`),
  // queryProcessByType :(data) => request.post(`${baseUrl}/v1/pc/process/queryProcessByType`, data),// 根据类型查询自定义流程信息
  //企业、个人网站函询接口
  addInquiry: data =>
    request.post(`${baseUrl}/jyb-user-page/admin/Inquiry/add`, data),
  //获取函询类型
  getInquiryType: () =>
    request.get(`${baseUrl}/jyb-user-page/admin/Inquiry/inquiryType`),
  // 获取个人、企业、团队网站 客服列表
  getCustomer: (no, type) =>
    request.get(
      `${baseUrl}/jyb-user-page/allSiteCustomer/pc/list/${no}?siteType=${type}`
    ),
  // //获取网站简介模块显示
  // getComInfoShow:(no) => request.get(`${baseUrl}/jyb-user-page/companyInfoShowController/list`,{companyNo:no}),

  // 关注企业
  userFocus(data) {
    return request.post(`${api7}/api/userFocus`, data);
  },
  // 招标----------------
  /**
   * 招标列表
   */
  getTenderList(data) {
    return request.post(`${zhaocai_url}/ov1/tender/es/findTenderPage`, data, {
      headers: { platform: "pc" }
    });
  },
  /**
   * 招标菜单数量
   */
  getMenuCount(data) {
    return request.post(`${zhaocai_url}/ov1/tender/es/findMenuList`, data);
  },
  /**
   * 招标类型
   */
  getTenderType() {
    return request.get(
      `${zhaocai_url}/ov1/mechanicalLabor/tenderTypeSelectCustom?type=true`
    );
  },
  /**
   * 招标状态
   */
  getTenderState(data) {
    return request.get(`${zhaocai_url}/ov1/mechanicalLabor/tenderEsSelect`);
  },
  /**
   * 招标方式
   */
  getTenderMothed(data) {
    return request.get(
      `${zhaocai_url}/ov1/mechanicalLabor/tenderTypeBidWaySelect`
    );
  },
  /**
   * 金额范围
   */
  getMoneyStep(data) {
    return request.get(`${zhaocai_url}/ov1/mechanicalLabor/tenderMoneySelect`);
  },
  /**
   * 竞价列表
   */
  getBidPriceList(data) {
    return request.post(`${zhaocai_url}/ov1/tender/es/findBidPage`, data);
  },
  /**
   * 中标公告列表
   */
  getWinNoticeList(data) {
    return request.post(`${zhaocai_url}/ov1/tender/es/findBidNoticePage`, data);
  },
  // ov1/tender/es/findTenderPage
  // 招标类型-
  // 商品材料- /ov1/mechanicalLabor/findItemCatAllList
  // 专业工程，劳务分包 - /ov1/mechanicalLabor/findTeamCategoryAllList
  // 工程总包｜ 设计规划 ｜专业监理 | 地质勘察 | 造价咨询- ov1/tender/type/getTenderType/1
  // 机械租赁 - ov1/mechanicalLabor/findMechanicsCategoryList
  // 设备租赁 - ov1/mechanicalLabor/findEquipmentCategoryList
  // 房产租赁 - sgb-shebei/real/category/listCategory?type=0
  // 车辆租赁 - /ov1/mechanicalLabor/findCarTypeList
  // 招标状态 - /ov1/mechanicalLabor/tenderEsSelect
  // ov1/mechanicalLabor/tenderMoneySelect
  // 竞价------------------
  // 商品材料 - ov1/mechanicalLabor/findItemCatAllList
  // 专业工程 劳务分包- ov1/mechanicalLabor/findTeamCategoryAllList
  // 工程总包 专业监理 地质勘察 造价咨询 - ov1/tender/type/getTenderType/2
  // 设计规划 - ov1/tender/type/getTenderType/1
  // 机械租赁 - ov1/mechanicalLabor/findOnlyMachineryCategoryList
  // 设备租赁 - ov1/mechanicalLabor/findOnlyEquipmentCategoryList
  // 房产租赁 - sgb-shebei/real/category/listCategory?type=0
  // 车辆租赁 - /ov1/mechanicalLabor/findCarTypeList
  // 中标公告----------------
  // ov1/tender/es/findBidNoticePage

  // 需方发起列表接口
  getGroupList(data) {
    return request.post(`${jicai_url}/index/search`, data);
  },
  // 供方发起列表接口
  getGroupSaleList(data) {
    return request.post(`${jicai_url}/index/saleSearch`, data);
  },
  // 供方/需方--商品材料分类列表
  getGoodsCateList() {
    return request.get(`${api6}/itemCat/getTreeNodeByProvinceId`);
  },
  // 需方--机械租赁分类列表
  getJxCateList() {
    return request.get(`${jixie_url}/api/index/getCategory`);
  },
  // 需方--设备租赁分类列表
  getSbCateList() {
    return request.get(`${shebei_url}/api/equipment/rentInfo/category`);
  },
  // 需方--总条数
  getTotalCount(data) {
    return request.post(
      `${jicai_url}/index/searchCount?areacode=${data.areacode}&searchKey=${data.searchKey}`
    );
  },
  // 供方--总条数
  getSaleTotalCount(data) {
    return request.get(`${jicai_url}/productSaleRecord/listCount`, data);
  },

  // 租赁接口
  getSearchList(data) {
    let url = '/lease/getSearchResult'
    // let url = +data.rentType === 1
    //     ? "/lease/getSearchResult"
    //     : "/lease/getAllRentLeaseInfo";
    return request.post(
      `${shebei_url}${url}`,
      data
    );
  },
  // 租赁类目接口
  getCateList(data) {
    return request.post(
      `${zulinUrl_es}/v1/api/index/rentLease/getModelBySearchKey`,
      data
    );
  },
  // 租赁分类接口
  getClassList(data) {
    return request.post(
      `${zulinUrl_es}/v1/api/index/leaceCategoy/getCategoryByType`,
      data
    );
  },
  // 物流接口
  getFindGoods: data =>
    request.post(`${wuliuUrl}/index/goodsSource/searchByKeyword`, data),
  getFindDriver: data =>
    request.post(`${wuliuUrl}/index/driver/searchByKeyword`, data),
  //获取地址信息
  getAreaById: id => request.get(api6 + "/api/area/getAreaById/" + id),
  //商品数据
  searchGoods: data => {
    // let id;
    // if (process.client) {
    //   id = JSON.parse(cookie.cookieRead("area")).id;
    // }
    // return request.post(api8 + '/v1/commodity/summary', data);
    return request.post(api8 + "/v1/commodity/v3/goods/query", data);
  },
  //企业信息
  getSearchSupplier: data => {
    return request.post(api7 + "/api/firstPage/getSupplierList", data);
  },

  //企业服务
  searchComList: data => {
    return request.post(api9 + "/v1/comService/searchComList", data);
  },
  // 搜索企业
  homeSearchComList: data => {
    return request.post(api9 + "/v1/comService/homeSearchComList", data);
  },
  //物流 获取所在地
  getAreaListData: async function() {
    return new Promise((resolve, reject) => {
      axios
        .get(api1 + "/api/equipment/rentInfo/getaddress")
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  //物流 获取全部省市区
  getAllAreaList: params =>
    request.get(api7 + "/api/auth/getAllAreaList", params),
  //物流 报价
  goodsOffer: params => request.post(api + "/goodsOffer/offer", params),
  //物流 车主下单
  driverOrder: params => request.post(api + "/carOffer/driverOrder", params),
  //物流 货源详情
  goodsSourceDetail: async data => {
    return new Promise(async (resolve, reject) => {
      axios
        .get(api + "/goodsSource/detail", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  //机械租赁
  searchMechanical: (params, data) => {
    return request.post(
      api10 +
        `/search/searchRentInfo?page=${params.page}&limit=${params.limit}`,
      data
    );
  },
  //设备租赁
  searchEquipment: (params, data) => {
    return request.post(
      api1 +
        `/api/search/findItem?page=${params.page}&size=${params.limit}&sort=0`,
      data
    );
  },
  //获取行业分类接口
  industryCategoryList: (params, data) => {
    return request.get(api9 + "/industryCategory/getAll");
  },
  getFilterParamVales: id =>
    request.get(api6 + `/v1/sku/commodity/getFilterParamVales/${id}`),
  getgroupCount: (params) => {
    return request.get(jicai_url + "/index/searchCount", params);
  },
  getgroupCountSaleRecord: (params) => {
    return request.get(jicai_url + "/productSaleRecord/listCount", params);
  }
};

