import request from '@/http'
var env = require("../../env");
import qs from 'qs'
const qiguan = env[process.env.environment].VUE_APP_QIGUAN
const url = env[process.env.environment].VUE_APP_LOGINAPI;
const authUrlType = env[process.env.environment].VUE_APP_ENTERPRISE;
let baseUrl = url + qiguan;

const ApiUrl = `${env[process.env.environment].VUE_APP_LOGINAPI}${env[process.env.environment].VUE_APP_ENTERPRISE}`
const api = {
  getUserName: (data) => request.post(`${ApiUrl}/v2/api/companyDepartment/findRelevanceDetailByIMAccountList`, data),
  getUserAccount: (data) => request.post(`/v2/api/companyDepartment/findIMAccountByTel`, data),

  searchUser: (data) => request.post('/v1/organization/getAllUserByCompany', data),
  //开始会议
  startedMeeting: (data) => request.post(baseUrl + '/v1/app/webMeeting/startedMeeting', data),
  //结束会议
  // endedMeeting: (data) => request.postHeaders(baseUrl + '/v1/app/webMeeting/endedMeeting',qs.stringify(data)),
  endedMeeting: (data) => request.post(baseUrl + `/v1/app/webMeeting/endedMeeting?id=${data}`),
  //已结束的会议
  // queryEndedMeeting: (data) => request.postHeaders(baseUrl + '/v1/app/webMeeting/queryEndedMeeting', qs.stringify(data)),
  //正在进行的会议
  // queryMeetingList: (data) => request.postHeaders(baseUrl + '/v1/app/webMeeting/querystartedMeeting', qs.stringify(data)),
  //离开进入会议.改变状态
  changeState: (data) => request.post(baseUrl + '/v1/app/webMeeting/inOroutMeeting', data),
  //查询会议内容及设置
  // queryMeetingInfo: (data) => request.postHeaders(baseUrl + '/v1/app/webMeeting/queryStartedMeetingByMeetingNo', qs.stringify(data)),
  queryMeetingInfo: (data) => request.post(baseUrl + `/v1/app/webMeeting/queryStartedMeetingByMeetingNo?meetingNo=${data}`),
  //锁定会议/
  // setLockMeeting: (data) => request.postHeaders(baseUrl + '/v1/app/webMeeting/queryLockMeeting', qs.stringify(data)),
  setLockMeeting: (data) => request.postHeaders(baseUrl + `/v1/app/webMeeting/queryLockMeeting?meetingNo=${data}`),
  //锁定会议/
  // setMuteMeeting: (data) => request.postHeaders(baseUrl + '/v1/app/webMeeting/queryMute', qs.stringify(data)),
  setMuteMeeting: (data) => request.postHeaders(baseUrl + `/v1/app/webMeeting/queryMute?meetingNo=${data}`),
  //主持人转移
  // transferHost: (data) => request.postHeaders(baseUrl + '/v1/app/webMeeting/transferHost', qs.stringify(data)),
  transferHost: (data) => request.postHeaders(baseUrl + `/v1/app/webMeeting/transferHost?meetingNo=${data.meetingNo}&imAccount=${data.imAccount}`),

  //会议
  list: () => request.get(url + authUrlType + `/v1/organization/getOrganizationAndContacts`),  //  /v1/organization/getOrganization
  userNodelist: () => request.get(url + authUrlType + `/v1/organization/getOrganization`),
  getUserByParentId: (org) => request.get(url + authUrlType + `/v1/organization/getUserByParentId/${org}`),
}
export default api
