import axios from '@/http'
import api from './api'
import { AppConfig } from '@/config'

const apiUser = {
  saveInvoiceDetail: function(data){ // 获取企业信息
    return new Promise(async (resolve, reject) => {
      axios({
        method: 'post',
        url: api + '/api/auth/saveInvoice',
        data
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
    // saveInvoiceDetail: (params) => request.post('/api/auth/saveInvoice', params),
  },
  getUserInfo: async function() {
    return new Promise(async (resolve, reject) => {
      axios({
        method: 'get',
        url: api + '/api/user/getCurrentUserInfoV2'
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getInvoice: async function() {
    return new Promise(async (resolve, reject) => {
      axios({
        method: 'get',
        url: '/v2/api/cart/getInvoice'
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
	getInvoiceNewList: async function() {
		return new Promise(async (resolve, reject) => {
			axios({
				method: 'get',
				url: '/v2/api/cart/getInvoices'
			})
				.then(res => {
					resolve(res)
				})
				.catch(err => {
					reject(err)
				})
		})
	},
  getEnterprisePhoto: async function(params) {
    return new Promise(async (resolve, reject) => {
      axios({
        method: 'get',
        url: api + '/companyAlbum/companyAlbum/'+params.companyNo,
        params: params.pageParam
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getcompanyInfoCustomer: async function(params) {
    return new Promise(async (resolve, reject) => {
      axios({
        method: 'get',
        url: api + '/companyInfoCustomer/pc/list/'+params,
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getcompanyAdImages: async function(params) {
    return new Promise(async (resolve, reject) => {
      axios({
        method: 'get',
        url: api + '/companyInfoAd/pc/list/'+params,
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendLogout: async function() {
    return new Promise(async (resolve, reject) => {
      axios({
        method: 'get',
        url: AppConfig.centerLoginUrl + AppConfig.authUrlType + '/sv1/logout',
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
export default apiUser
