import store from "@/store/index";
import cookie from "@/storage/cookies.js";
import axios from "@/http";
var env = require("../../env");
const base_url = env[process.env.environment].VUE_BASE_URL;
const authUrlType = env[process.env.environment].VUE_APP_AUTHURLTYPE
// const apiLogin = (params) => request.postLogin('/api/shop/token', params) // 发送请求
const setToken = token => {
  // 储存token
  store().commit("SET_LOGINTYPE", true);
  store().commit("SET_TOKEN", token);
  cookie.cookieSet("token", token, 1);
  // save('token', token)
};
const readToken = async () => {
  // 获取token
  // if (read('token')) {
  let token = cookie.cookieRead("token");
  setToken(token);
  // await getUser()
  // } else {

  // }
};
const clearToken = () => {
  // 清除token
  // clear('token')
  store().commit("SET_LOGINTYPE", false);
  store().commit("SET_TOKEN", null);
  // store().commit('SET_USER', {})
  cookie.cookieClear("token");
  cookie.cookieClear("USER_ACCESS_TOKEN")
  // router.push({ path: '/login' })
};
// const login = async (params) => { // 登录
//   let data = await apiLogin(params)
//   if (data.status === 200) {
//     setToken(data.data.access_token)
//     router.push({ path: '/home' })
//   }
// }

const login = form => {
  return axios.postLogin(
    `${env[process.env.environment].VUE_APP_LOGINAPI}${
      env[process.env.environment].VUE_APP_AUTHURLTYPE
    }/oauth/token`,
    form,
    {
      headers: {
        Authorization:
          "Basic MTE3NTI3Njk0NDMyNjAwNDczNjo2M2NkMzYwM2Q5YTc0ZTNiYmI4ZDhiOGE5NmQ3MWVlZA==",
      }
    },
    true
  );
};

const logout = ()=>{
  return axios.get(base_url + authUrlType + '/logout') 
}

const getSmsCode = data =>
  axios.post(
    `${env[process.env.environment].VUE_APP_LOGINAPI}${
      env[process.env.environment].VUE_APP_AUTHURLTYPE
    }/ov1/sms`,
    data
  );
export { setToken, readToken, clearToken, login, logout, getSmsCode };
