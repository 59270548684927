import request from "@/http";
import cookie from "@/storage/cookies.js";
var env = require("../../env");

const api = env[process.env.environment].VUE_APP_APIMATERIALS;
const apis = env[process.env.environment].VUE_APP_APIMATERIALS_NEW;

var id;
if (process.client && cookie &&cookie.cookieRead("area")) {
  id = JSON.parse(cookie.cookieRead("area")).id ||undefined;
}

if (id == undefined) {
  id = 110000;
}
const apiMaterials = {
  shopHasFinish: () => request.get(api + "/api/item/shopHasFinish"),
  getClassify: () =>
    request.get(api + "/homeCategory/indexCategory?areaCode=" + ''),
  getListByAreaCode: () => request.get(apis + "/v1/material/category")
};
export default apiMaterials;
