import axios from 'axios'
const httpMapRequest = axios.create({})
export const httpMap = {
  getPositionApi(provinceName) {
    const url = `https://restapi.amap.com/v3/geocode/geo?address=${provinceName}&key=4c679a539659f616d70283f608985b66`
    return new Promise((resolve, reject) => {
      httpMapRequest.get(url).then(res => {
        resolve(res);
      });
    });
  }
}