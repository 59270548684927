import request from '@/http'
const env = require("../../env")
const zhaocai_url = env[process.env.environment].VUE_APP_APIRECRUITMENT
console.log(zhaocai_url,'zhaocai_url-=-=-=');
const local_url = 'http://localhost:8088'

const apiFilterSearch = {
	// 招标类型 - 原本20种类型
	getTenderType: () => request.get(`${zhaocai_url}/ov1/mechanicalLabor/tenderTypeSelectCustom`),
	// 招标类型树结构
	getTenderTypeTree: (data) => request.post(`${zhaocai_url}/ov1/typeDict/findChildrenTreeByParentNoAndBusinessScenario`, data),
	// 招标类型树结构 - 自定义树配置
	findChildrenTreeStructure: (data) => request.get(`${local_url}/ov1/typeDict/findChildrenTreeStructure/${data.businessType}/${data.queryPosition}`),
	// 竞价类型
	getbidType: () => request.get(`${zhaocai_url}/ov1/bid/getAllBidType`),
	// 招采方式
	getTenderWay: (data) => request.get(`${zhaocai_url}/ov1/mechanicalLabor/tenderWayByType/${data}`),
	getTenderWayByTypeV1: (data) => request.get(`${local_url}/ov1/mechanicalLabor/tenderWayByTypeV1/${data}`),
	// 市列表
	getCitys: (data) => request.get(`${zhaocai_url}/old/api/font/citys`, data),
	// 报名截止时间options
	getTenderTimeOptions: () => request.get(`${zhaocai_url}/ov1/mechanicalLabor/tenderTimeSelect`),
	// 资格审查方式options
	getTenderWayOptions: () => request.get(`${zhaocai_url}/ov1/mechanicalLabor/tenderTypeBidWaySelect`),
	// 招标方式options
	getTenderMethodOptions: () => request.get(`${zhaocai_url}/ov1/mechanicalLabor/getBiddingMethod`),
	// 数据来源options
	getDataSourcesOptions: () => request.get(`${zhaocai_url}/ov1/mechanicalLabor/getDataSources`),
	// 预算金额范围
	getMoneySelectOptions: () => request.get(`${zhaocai_url}/ov1/mechanicalLabor/tenderMoneySelect`),
	// 招标状态
	getTenderState: () => request.get(`${zhaocai_url}/ov1/mechanicalLabor/tenderBidderSelect`),
	// 报名开始时间
	getTenderTime: () => request.get(`${zhaocai_url}/ov1/mechanicalLabor/tenderTimeSelect`),
	// 发布时间
	getProjectTime: () => request.get(`${zhaocai_url}/ov1/demandNotice/projectTimeSelect`),
	// 招标公告|招标大厅-招采方式-不限 列表排序
	getSortAll: () => request.get(`${zhaocai_url}/ov1/mechanicalLabor/getTenderingSortSelectTab`),
	// 招标公告列表排序
	// getSortNotice: () => request.get(`${zcUrl}/ov1/mechanicalLabor/getTenderSortSelect`),
	// 招标预告列表排序
	getSortPrenotice: () => request.get(`${zhaocai_url}/ov1/tender/preNotice/getTenderPreNoticeSortSelect`),
	// 动态竞价列表排序
	getSortDysincBid: () => request.get(`${zhaocai_url}/ov1/mechanicalLabor/bidSortSelectTab`),
	// 中标公告排序
	getSorWinTender: () => request.get(`${zhaocai_url}/ov1/base/biddingNotice/getBidderNoticeSortSelect`),
  }
  export default apiFilterSearch
  