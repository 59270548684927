import axios from "@/http";
console.log(axios);
export const getQgbAndroid = () => {
  return axios.get(
    "https://gateway.jingyingbang.com/sgb-app-manager/v1/app/common/getAppUpdateUrl"
  );
};
export const getqldAndroid = () => {
  return axios.get(
    "/sgb-app-manager/v1/app/common/api/logList?type=android&category=enterpriseAlliance&page=1&size=15"
  );
};
