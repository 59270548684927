// 收货地址 api
import axios from '@/http'
import { AppConfig } from '@/config'
let baseUrl = AppConfig.userApi
let teamUrl = AppConfig.teamUrl
// let baseUrl = 'http://yuyue.ngrok.gcw.net:90'

export const api = {
  getSupplierList: async function(data){
    return new Promise(async (resolve, reject) => {
      // 首页获取供应商列表
      axios({
        method: 'post',
        url: baseUrl + '/api/firstPage/getRecommendSupplier',
        data
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getSearchSupplier: async function(data){
    return new Promise(async (resolve, reject) => {
      // 发现供应商列表
      axios({
        method: 'post',
        url: baseUrl + '/api/firstPage/getSupplierList',
        data
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getNeedsList: async function(data){
    return new Promise(async (resolve, reject) => {
      // 用户需求列表查询
      axios({
        method: 'post',
        url: baseUrl + '/api/firstPage/getNeedsList',
        data
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getIndexNeeds: async function(data){
    return new Promise(async (resolve, reject) => {
      // 获取首页商品需求列表
      axios({
        method: 'post',
        url: baseUrl + '/api/firstPage/getRecommendNeeds',
        data
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getNeedDetail: async function(data){
    return new Promise(async (resolve, reject) => {
      // 获取首页商品需求列表
      axios({
        method: 'post',
        url: baseUrl + '/api/firstPage/getFirstPageNeedsDetail',
        data
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addNeeds: async function(data){
    return new Promise(async (resolve, reject) => {
      // 获取首页商品需求列表
      axios({
        method: 'post',
        url: baseUrl + '/api/needs/addNeeds',
        data
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getArea: async function(){
    return new Promise(async (resolve, reject) => {
      // 获取首页商品需求列表
      axios({
        method: 'get',
        url: `${teamUrl}/sysArea/getAllArea`,
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getAreaByCode: async function(params){
    return new Promise(async (resolve, reject) => {
      // 获取首页商品需求列表
      axios({
        method: 'get',
        url: `${baseUrl}/api/auth/1`,
        params
      })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}