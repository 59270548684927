import axios from '@/http'

var env = require("../../env");
const api = env[process.env.environment].VUE_APP_APIMATERIALS
const base_url = env[process.env.environment].VUE_BASE_URL
let url = api
const apiHelp = {
  getBotList: async () => {
    return new Promise((resolve, reject) => {
      axios.get(`${base_url}/sgb-review/helpQuickLink/list`)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // 列表
  // https://api.sgbwl.com/sgb-review/helpQuickLink/list
  getContentList: async function () {
    return new Promise((resolve, reject) => {
      axios.get(url + '/api/content/list', {
        isContent: true
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  // 获取详情
  getContentDetails: async function (id) {
    return new Promise((resolve, reject) => {
      axios.get(url + '/api/content/' + id, {})
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getQuickLink: function () {
    return new Promise((resolve, reject) => {
      axios.get(url + '/quickLink/list')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
export default apiHelp
