let env = require("../../env");
let url = env[process.env.environment].VUE_APP_APIMATERIALS;
import http from "@/http";

export const apiInquiryPrice = {
  // 获取商品品牌
  getProducts: () => http.get(url + "/itemCat/getTreeNodeByProvinceId"),
  // 获取商品品牌
  fetchBrand: id => http.get(url + `/api/item/supplierGetItemBrand/${id}`),
  fetchProperty: id => http.get(url + `/api/item/supplierGetParamVal2/${id}`), // 获取商品属性
  fetchUnit: () => http.get(url + `/api/item/supplierGetUnit`), // 获取商品单位
  searchCompany: params => http.post(url + "/enquiry/searchCompany", params),
  concreteStrongAll: () => http.get(url + `/api/concrete/concreteStrongAll`), // 获取商砼所有强度等级
  concreteAdmixtureAll: () =>
    http.get(url + `/api/concreteAdmixture/concreteAdmixtureAll`), // 获取所有外加剂

  // 保存询价
  savePublish: params => http.post(url + "/enquiry/publish", params)
};

